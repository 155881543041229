import Modale from '../../common/ui/modale.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'
import Onglets from '../../common/ui/onglets.vue'
import Input from '../../common/forms/Input.vue'
import Select from '../../common/forms/Select.vue'
import Chip from '../../common/ui/chip.vue'
import axios from 'axios'
import _ from 'lodash'
export default {
	data(){
		return {
			onglets: [
				{ code: 'existing', label: this.$t('parcel_list_existante') },
				{ code: 'new', label: this.$t('parcel_list_nouvelle') }
			],
			onglet_active: this.$store.state.parcelle.parcelles_lists.length ? 'existing' : 'new',
			nom_liste: null,
			loading: false,
			listsToAdd: [],
			confirmation: false,
			errors: {}
		}
	},
	watch: {
		onglet_active() {
			this.listsToAdd = []
		}
	},
	methods: {
		ajouterListe() {
			let self = this
			this.loading = true
			axios.post('api=inex/parcelles/list', {
				name: this.nom_liste,
				parcelle_ids: []
			}).then(response => {
				if(response.data?.list_id){
					self.listsToAdd.push(response.data)
					self.addParcelToLists()
				}
			}).catch(error => {
				let errors = _.cloneDeep(self.errors)
				errors.nom_liste = error.response.data.detail
				self.errors = errors
			}).finally(() => {
				self.loading = false
				self.$store.dispatch('loadParcelLists')
			})
		},
		addParcelToLists() {
			this.$store.dispatch('addParcelToLists', this.listsToAdd)
			this.confirmation = true
		},
		onSelectList(list_id) {
			const list = this.$store.state.parcelle.parcelles_lists.find(list => list.list_id == list_id)
			this.listsToAdd.push(list)
		},
		removeList(list) {
			console.log(list)
			this.listsToAdd = this.listsToAdd.filter(l => l.list_id != list.list_id)
		}
	},
	computed: {
		listsOptions() {
			let lists = this.$store.state.parcelle.parcelles_lists

			let alreadyInLists = []
			if(this.$store.state.parcelle.parcelle_on_detailled){
				alreadyInLists = this.$store.getters.getParcelleDetailledContentByFormat('parcel_lists')?.value ?? []
				if(alreadyInLists.length) alreadyInLists = alreadyInLists.map(list => list.list_id)
			}else{
				alreadyInLists = this.$store.getters.getParcelleContentByFormat('parcel_lists')?.value ?? []
				if(alreadyInLists.length) alreadyInLists = alreadyInLists.map(list => list.list_id)
			}

			// Retire celles dans lesquelles la parcelle est déjà
			lists = lists.filter(list => !alreadyInLists.includes(list.list_id))
			
			// Retire celles qui sont déjà dans listsToAdd
			lists = lists.filter(list => !this.listsToAdd.some(l => l.list_id == list.list_id))
			
			return lists.map(list => ({label: list.name, value: list.list_id}))
		}
	},
	components:{
		Modale,
		LoadingBtn,
		Onglets,
		Input,
		Select,
		Chip
	}
}