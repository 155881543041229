export default {
	events: ['click_before_icon', 'click_after_icon'],
	props: {
		label: String,

		before_icon: String,
		before_icon_viewBox: {
			type: String,
			default: '0 0 24 24'
		},
		before_icon_width: {
			type: Number,
			default: 16
		},
		before_icon_height: {
			type: Number,
			default: 16
		},
		before_icon_action: Boolean,

		after_icon: String,
		after_icon_viewBox: {
			type: String,
			default: '0 0 24 24'
		},
		after_icon_width: {
			type: Number,
			default: 16
		},
		after_icon_height: {
			type: Number,
			default: 16
		},
		after_icon_action: Boolean
	},
	methods: {
		trigger_before_icon_action() {
			console.log('trigger_before_icon_action')
			this.$emit('click_before_icon')
		},
		trigger_after_icon_action() {
			console.log('trigger_after_icon_action')
			this.$emit('click_after_icon')
		}
	}
}