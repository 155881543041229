export default {
	methods: {
        // Retire toutes les balises HTML en ne conservant que les sauts de ligne, y compris les enchainements de paragraphes
		onlyBr(text){
			// transforme les balises <br> en --br--
			text = text.replace(/<br[\s]*>/g, '--br--')
			// transforme les enchainements de paragraphes en --br--
			text = text.replace(/<\/p>[\s]*<p>/g, '--br--')
			// Retire toutes les autres balises
			text = text.replace(/<[^>]*>/g, '')
			// transforme les --br-- en <br>
			text = text.replace(/--br--/g, '<br>')
			return text
		},

        // Retourne un objet avec les propriétés text et full
        // text est la version réduite du texte, avec des points de suspension si elle dépasse la limite
        // full est la version complète du texte
        // Si le texte ne dépasse pas la limite, text est renvoyé tel quel
        smallFull(text, limit){
            if(limit && text.length > limit){
                return {
                    text: text.substring(0, limit) + '...',
                    full: text
                }
            }
            return text
        }
	}
}