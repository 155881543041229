import { render, staticRenderFns } from "./chip.vue?vue&type=template&id=174fe064&scoped=true"
import script from "./chip.js?vue&type=script&lang=js&external"
export * from "./chip.js?vue&type=script&lang=js&external"
import style0 from "./chip.vue?vue&type=style&index=0&id=174fe064&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174fe064",
  null
  
)

export default component.exports