import cloneDeep from 'lodash/cloneDeep'

export default {
	name: 'Onglets',
	data() {
		return {
			active: null
		}
	},
	props: {
		value: {
			type: String,
			default: null
		},
		onglets: {
			type: Array,
			default: []
		}
	},
	mounted() {
		this.active = cloneDeep(this.value)
		if(!this.active) {
			this.active = this.onglets[0].code
		}
	},
	methods: {
		selectOnglet(code) {
			this.active = code;
			this.$emit('input', code);
		}
	}
}
