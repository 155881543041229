export default {
    methods: {
        getFormattedDateTimeForFileName() {
            const now = new Date();
            
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
            const day = String(now.getDate()).padStart(2, '0');
            
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
        
            return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
        },

        // Formatte une date pour l'afficher à l'écran : 8 mars 2024 (pas de zéro devant le jour)
        getFormattedDateForScreen(date) {
            if(typeof date == 'string') date = new Date(date);
            return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
        }
    }
}