export default {
    methods: {
        // Arrondi à :
        // - 2 décimales si la valeur est inférieure à 0.1
        // - 1 décimale si la valeur est inférieure à 10
        // - L'entier supérieur si la valeur est supérieure à 10
        progressiveDecimalsCeil(number){
            //  Si la valeur est inférieure à 0.1, on arrondi à 2 décimales, à la décimale supérieure
            if(number < 0.1) number = Math.ceil(number * 100) / 100
            // Sinon, si la valeur est inférieure à 10, on arrondi à la décimale supérieure
            else if(number < 10) number = Math.ceil(number * 10) / 10
            // Sinon, on arrondi à l'entier supérieur
            else number = Math.ceil(number)
            return number
        }
    }
}