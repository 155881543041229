import _ from 'lodash'
import Loading from '../common/ui/loading_inex.vue'
import Strings from '../common/mixins/Strings'
import Numbers from '../common/mixins/Numbers'

export default {
    name: 'Parcelles',
    mixins: [Strings, Numbers],
    data() {
        return {
            columns: [
                {
                    code: 'commune',
                    label: this.$t('parcelles_liste_header_commune'),
                    type: 'string',
                    limit: 60
                },
                {
                    code: 'numero',
                    label: this.$t('parcelles_liste_header_numero'),
                    type: 'string'
                },
                {
                    code: 'superficie',
                    label: this.$t('parcelles_liste_header_superficie'),
                    type: 'number'
                },
                {
                    code: 'proprietaire',
                    label: this.$t('parcelles_liste_header_proprietaire'),
                    type: 'string',
                    limit: 45
                },
                {
                    code: 'categorie',
                    label: this.$t('parcelles_liste_header_categorie'),
                    type: 'string',
                    limit: 60
                },
                {
                    code: 'siren',
                    label: this.$t('parcelles_liste_header_siren'),
                    type: 'string'
                },
                {
                    code: 'note_publique',
                    label: this.$t('parcelles_liste_header_note_publique'),
                    type: 'string',
                    limit: 60
                }
            ]
        }
    },
    methods: {
        getContent(parcelle, code){
            let param = this.columns.find(column => column.code == code)
            let value = ''
            switch(code){
                case 'commune': 
                    value = parcelle.nom_com + ' (' + parcelle.code_dep + parcelle.code_com + ')'
                    break
                case 'numero': 
                    value = parcelle.section + ' ' + parcelle.numero
                    break
                case 'superficie': 
                    value = parcelle.surface / 10000 // m2 -> ha
                    break
                case 'proprietaire':
                    value = parcelle.proprietaires?.[0]?.nom
                    break
                case 'categorie':
                    value = parcelle.proprietaires?.[0]?.groupe_personne
                    break
                case 'siren':
                    value = parcelle.proprietaires?.[0]?.siren ? parcelle.proprietaires?.[0]?.siren.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : null
                    break
                case 'note_publique':
                    value = parcelle.public_comment
                    break
                case 'note_privee':
                    value = parcelle.private_comment
                    break
                default: 
                    value = '??'
            }
            if(param.type == 'number') value = this.progressiveDecimalsCeil(value)
            if(param.limit) value = this.formatText(value, param.limit)
            return value
        },
        showParcelle(parcelle){
            let identifiant = parcelle.idu
            this.$store.dispatch('setParcelleOnId', identifiant);
        },
        formatText(text, limit){
            if(!text) return null
            text = this.onlyBr(text)
            return this.smallFull(text, limit)
        }
    },
    computed: {
        getParcelles(){
            let self = this
            let parcelles = this.$store.state.parcelle.parcelles_list ?? this.$store.state.parcelle.parcelles_geojson
            if(!parcelles) return []

            let parcellesGeojson = _.cloneDeep(parcelles)    
            parcelles = parcellesGeojson.features.map(parcelle => {
                let properties = parcelle.properties

                self.columns.forEach(column => {
                    properties[column.code] = self.getContent(properties, column.code)
                })  

                return properties
            })

            return parcelles
        },
        getParcellesOrdered(){
            let parcelles = this.getParcelles
            let order = this.$store.state.parcelle.parcelles_list_order
            let direction = this.$store.state.parcelle.parcelles_list_order_direction

            // D'abord on classe les parcelles par ordre de propriétaire
            parcelles = _.sortBy(parcelles, 'proprietaire')

            // Classement numérique
            if(this.columns.find(column => column.code == order).type == 'number'){
                parcelles.sort((a, b) => {
                    if(direction == 'asc'){
                        return (a[order]) - (b[order])
                    }else{
                        return (b[order]) - (a[order])
                    }
                })
 
            // Classement alphabétique
            }else{
                parcelles = _.sortBy(parcelles, order)
                if(direction == 'desc') parcelles.reverse()
            }
            
            // Déplacer la parcelle sélectionnée en première position
            const selectedParcelleId = this.$store.state.parcelle.parcelle_on_id
            if (selectedParcelleId) {
                const selectedIndex = parcelles.findIndex(p => p.idu === selectedParcelleId)
                if (selectedIndex > -1) {
                    const selectedParcelle = parcelles.splice(selectedIndex, 1)[0]
                    parcelles.unshift(selectedParcelle)
                }
            }
            return parcelles
        }
    },
    components: {
        Loading
    }
}